<template>
  <div class="endpointGet p-4">
    <LoadingPlaceholder v-if="loading" />
    <div
      v-else
      class="row"
    >
      <div class="col-12 col-sm-5">
        <label>{{ $t('installationId') }}</label>
        <multiselect
          ref="vms"
          v-model="selectedInstallation"
          :class="['m-input m-input--air', { 'is-invalid': this.$validator.errors.has('InstallationId') } ]"
          :options="installations"
          :taggable="false"
          :show-labels="true"
          :searchable="true"
          :close-on-select="true"
          :hide-selected="true"
          :multiple="false"
          :deselect-label="$t('unconfiguredDevicesDisplayComponent.clickToDeselect')"
          :select-label="$t('unconfiguredDevicesDisplayComponent.clickToSelect')"
          :placeholder="$t('unconfiguredDevicesDisplayComponent.chooseAnInstallation')"
          label="name"
          track-by="id"
        >
          <template
            slot="option"
            slot-scope="{option}"
          >
            <span>{{ option.name }}</span>
          </template>
          <template
            slot="tag"
            slot-scope="{option}"
          >
            <span
              class="m-badge m-badge--primary m-badge--wide"
              @click="removeFromSelection(option)"
            >{{ option.name }} &times;</span>
          </template>
        </multiselect>
      </div>
      <div class="col-12 col-sm-5">
        <label>{{ $t('component') }}</label>
        <select
          v-model="component"
          class="form-control"
        >
          <option :value="null">
            {{ $t('pleaseSelectOne') }}
          </option>
          <option value="StartFinish">
            {{ $t('endpointGetComponent.startFinish') }}
          </option>
          <option value="VideoDirector">
            {{ $t('endpointGetComponent.videoDirector') }}
          </option>
          <option value="CameraManagement">
            {{ $t('endpointGetComponent.cameraManagement') }}
          </option>
          <option value="VideoPostProcessing">
            {{ $t('endpointGetComponent.videoPostProcessing') }}
          </option>
          <option value="VideoPublisher">
            {{ $t('endpointGetComponent.videoPublisher') }}
          </option>
          <option value="DeviceManagement">
            {{ $t('endpointGetComponent.devicemanagement') }}
          </option>
        </select>
      </div>
      <div class="col-12 col-sm-2">
        <label>&nbsp;</label>
        <button
          class="btn btn-primary btn-block"
          @click="getData()"
        >
          {{ $t('get') }}
        </button>
      </div>
    </div>
    <pre
      v-if="url"
      class="mt-4"
    >
      <span>{{ $t('endpointGetComponent.url') }}:</span>
      <CopyToClipboard :copy-data="url" />
    </pre>
  </div>
</template>

<script>
export default {
  name: 'EndpointGet',
  components: {
    CopyToClipboard: () => import('@/components/CopyToClipboard.vue'),
    Multiselect: () => import('vue-multiselect')
  },
  data () {
    return {
      selectedInstallation: null,
      installations: null,
      component: null,
      url: null,
      loading: true
    }
  },
  created () {
    this.getInstallations();
  },
  methods: {
    getData () {
      this.axios.get(`ComponentEndpoint/Get?installationId=${ this.selectedInstallation.id }&component=${ this.component }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.url = response.data;
        });
    },
    getInstallations: function () {
    this.axios.get('/Installation/GetAll')
      .then((response) => {
        this.installations = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
pre {
  white-space: normal;
}
</style>
